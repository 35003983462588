import Vue from 'vue';

// Директива для поля ввода телефона
export const inputPhoneMask = {
  bind(el) {
    // eslint-disable-next-line func-names
    // eslint-disable-next-line no-param-reassign
    el.oninput = function (e) {
      if (!e.isTrusted) {
        return;
      }

      const x = this.value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      const y = '+7';
      const code = x[2].replace(/^8/, ''); // code of city or operator - 983, 924, 902 и т.д.

      if (!code && x[1] !== '' && x[1]) {
        this.value = x[1] === '7' ? y : y + x[1];
      } else {
        // eslint-disable-next-line prefer-template
        this.value = !x[3] ? y + code : y + ' (' + code + ') ' + x[3] + (x[4] ? '-' + x[4] : '') + (x[5] ? `-${x[5]}` : '');
      }
    };
  }
};

export default inputPhoneMask;
Vue.directive('inputPhoneMask', inputPhoneMask);
