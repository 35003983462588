<template>
  <b-modal id="callback-modal" title="ЗАКАЗАТЬ ЗВОНОК" hide-footer no-stacking>
    <p>
      <b>
        Номер телефона:
      </b>
    </p>
    <InputPhone v-model="phone" />
    <p :class="$style.prompt">Укажите номер телефона в формате 7 (xxx) xxx-xx-xx</p>
    <BaseButton @click="sendRequest" :disabled="phone.length < 18">
      <template #text> Заказать обратный звонок </template>
    </BaseButton>
  </b-modal>
</template>

<script>
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import InputPhone from '@/components/shared/inputs/InputPhone.vue';
import URL from '@/basUrl';
import { catchFunction, ShowErrorToUser, ShowSuccesToUser } from '@/additional_helpers/helpers';

export default {
  SUCCESS_MESSAGE: 'Спасибо! Ваша заявка принята. Наши специалисты свяжутся с Вами в ближайшее время',

  data() {
    return {
      phone: ''
    };
  },

  components: {
    BaseButton,
    InputPhone
  },

  methods: {
    sendRequest() {
      fetch(URL.CALL_BACK, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `CustomerId=3325251&TargetPhoneNumber=${this.phone.replace(
          /\D/g,
          ''
        )}&GroupName=%D0%9E%D0%B1%D1%80%D0%B0%D1%82%D0%BD%D1%8B%D0%B9%20%D0%B2%D1%8B%D0%B7%D0%BE%D0%B2%20%D1%81%20Sales`
      })
        .then(async (response) => ({ text: await response.text(), status: response.status }))
        .then((result) => {
          if (result.status === 200) {
            ShowSuccesToUser(this.$options.SUCCESS_MESSAGE);
            return;
          }
          ShowErrorToUser(JSON.parse(result.text));
        })
        .catch((e) => catchFunction(e));

      this.$bvModal.hide('callback-modal');
    }
  }
};
</script>

<style lang="scss" module>
.prompt {
  padding-top: 0.25rem;
  color: $lightGrey;
}
</style>
