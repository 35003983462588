<template>
  <b-form-input
    v-inputPhoneMask
    :value="value"
    v-bind="$attrs"
    @input="updateNumber"
    placeholder="7 (xxx) xxx - xx - xx"
  ></b-form-input>
</template>

<script>
import { inputPhoneMask } from '@/user_directives/inputPhoneMask';

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number],
      required: true
    }
  },

  directives: {
    inputPhoneMask
  },

  methods: {
    updateNumber(event) {
      this.$emit('input', event);
    }
  }
};
</script>

<style></style>
